import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { Link, StaticQuery, graphql } from "gatsby"
import MainHeader from './MainHeader'
import PostCard from './PostCard'

import { Navigation } from "."

// Styles
import "../../styles/app.css"

const readNextStyle = {
  display: `grid`,
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: 30,
  margin: '20px auto',
  maxWidth: 1040
}
/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */
const DefaultLayout = ({ pageTitle, data, children, bodyClass, isHome, nextPost, previousPost }) => {
  const site = data.allGhostSettings.edges[0].node

  return (
    <>
      <Helmet>
        <html lang={site.lang} />
        <style type="text/css">{`${site.codeinjection_styles}`}</style>
        <body className={bodyClass} />
      </Helmet>

      <div className="viewport">
        <div
          css={{ display: `flex`, flexDirection: `column` }}
          className="viewport-top"
        >
          {/* The main header section on top of the screen */}
          <MainHeader site={site} isHome={isHome} pageTitle={pageTitle} />
          <main className="site-main">
            {/* All the main content gets inserted here, index.js, post.js */}
            {children}
          </main>
        </div>

        <div className="viewport-bottom">

          {/* The footer at the very bottom of the screen */}
          <footer className="site-foot">
            {previousPost || nextPost ? <div css={{ padding: '30px 0', borderBottom: '1px solid #333' }}>
              <section css={readNextStyle} >
                {previousPost ? <PostCard key={previousPost.id} post={previousPost} /> : null}
                {nextPost ? <PostCard key={nextPost.id} post={nextPost} /> : null}
              </section>
            </div> : null}
            <div className="site-foot-nav container">
              <div className="site-foot-nav-left">
                <Link to="/">{site.title}</Link> ©{` `}
                {new Date().getFullYear()}
              </div>
              <div className="site-foot-nav-right">
                <Navigation
                  data={site.navigation}
                  navClass="site-foot-nav-item"
                />
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bodyClass: PropTypes.string,
  isHome: PropTypes.bool,
  pageTitle: PropTypes.string,
  data: PropTypes.shape({
    file: PropTypes.object,
    allGhostSettings: PropTypes.object.isRequired,
  }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
  <StaticQuery
    query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: { eq: "ghost-icon.png" }) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
    render={data => <DefaultLayout data={data} {...props} />}
  />
)

export default DefaultLayoutSettingsQuery
