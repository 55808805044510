import React from 'react';


const avatarStyle = {
  width: 36,
  borderWidth: 2,
  borderRadius: `100%`,
  border: `1px solid`,
}

const AuthorAvatar = ({profileImage, name}) =>  <div css={{ marginRight: 20 }}>
{profileImage ? (
  <img
    className="author-profile-image"
    css={avatarStyle}
    src={profileImage}
    alt={name}
  />
) : (
    <img
      css={avatarStyle}
      className="default-avatar"
      src="/images/icons/avatar.svg"
      alt={name}
    />
  )}
</div>

export default AuthorAvatar