import React from "react";
import Img from "gatsby-image";
import { Navigation } from ".";
import config from "../../utils/siteConfig";
import { useInView } from "react-intersection-observer";

const MainHeader = props => {
  const { pageTitle, site, isHome } = props;
  const [ref, inView] = useInView({ rootMargin: `-70px 0px` });
  const twitterUrl = site.twitter
    ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}`
    : null;
  const facebookUrl = site.facebook
    ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}`
    : null;
  return (
    <>
      <header
        ref={ref}
        className="site-head"
        style={{
          ...(site.cover_image && {
            backgroundImage: `url(${site.cover_image})`
          })
        }}
      >
        <div className="container">
          <div className="site-mast">
            <nav className="site-nav">
              <div className="site-nav-left">
                {/* The navigation items as setup in Ghost */}
                <Navigation
                  data={site.navigation}
                  navClass="site-nav-item"
                />
              </div>
            </nav>
            <div className="site-mast-right">
              {site.twitter && (
                <a
                  href={twitterUrl}
                  className="site-nav-item"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="site-nav-icon"
                    src="/images/icons/twitter.svg"
                    alt="Twitter"
                  />
                </a>
              )}
              {site.facebook && (
                <a
                  href={facebookUrl}
                  className="site-nav-item"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="site-nav-icon"
                    src="/images/icons/facebook.svg"
                    alt="Facebook"
                  />
                </a>
              )}
              <a
                className="site-nav-item"
                href={`https://feedly.com/i/subscription/feed/${config.siteUrl}/rss/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="site-nav-icon"
                  src="/images/icons/rss.svg"
                  alt="RSS Feed"
                />
              </a>
            </div>
          </div>
          {isHome ? (
            <div className="site-banner">
              <h1 className="site-banner-title">{site.title}</h1>
              <p className="site-banner-desc">
                {site.description}
              </p>
            </div>
          ) : null}
        </div>
      </header>
      <div
        css={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          background: "black",
          padding: "10px 4vw",
          display: inView ? 'none' : 'block',
          zIndex: inView ? -1 : 1,
        }}
      >
        <nav className="site-nav">
          <div className="site-nav-left">
            {/* The navigation items as setup in Ghost */}
            <a
              css={{
                marginRight: 32,
                color: "white",
                fontSize: "1.7rem",
                fontWeight: 700,
                fontHeight: "1.8rem"
              }}
              href="/"
            >
              {site.title}
            </a>
            {pageTitle ? (
              <span css={{ color: "#aaa", fontWeight: 400 }}>
                {pageTitle}
              </span>
            ) : null}
          </div>
        </nav>
      </div>
    </>
  );
};
export default MainHeader;
